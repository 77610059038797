













































































import { computed, defineComponent, PropType } from "@vue/composition-api";

import AttachmentItem from "@/app/components/Base/AttachmentItem.vue";
import UserListItem from "@/app/components/User/UserListItem.vue";
import { AttachmentItemMode } from "@/shared/enums/components/Base/AttachmentItem.enums";
import { projectActionPanelEnum } from "@/shared/enums/components/Project/ProjectDescription/projectActionPanel.enum";
import { Project } from "@/shared/types/schema.types";
import { formatDateTimeForDisplay } from "@/shared/utils";

export default defineComponent({
  name: "ProjectDescription",
  components: {
    AttachmentItem,
    UserListItem, 
  },
  props: {
    project: {
      type: Object as PropType<Project>,
      default: null,
    },
    mode: {
      type: String as PropType<projectActionPanelEnum>,
      default: projectActionPanelEnum.DESCRIPTION,
    },
  },

  setup(props) {
    const isResultInfo = computed(() => props.mode === projectActionPanelEnum.RESULT);
    const isDescriptionInfo = computed(() => props.mode === projectActionPanelEnum.DESCRIPTION);
    return {
      formatDateTimeForDisplay,
      AttachmentItemMode,
      isResultInfo,
      isDescriptionInfo,
    };
  },
});
