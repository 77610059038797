




















import { defineComponent } from "@vue/composition-api";

import BackLink from "@/app/components/Base/BackLink.vue";
import ProjectNavigation from "@/app/components/Project/ProjectNavigation.vue";
import { useRoute } from "@/shared/hooks";
import { useProjectShort } from "@/shared/hooks/fetchers";


export default defineComponent({
  name: "ProjectPageWrapper",
  components: {
    ProjectNavigation,
    BackLink, 
  },
  setup() {
    const route = useRoute();
    const projectId = route.params.id;
    const { data: project } = useProjectShort({ id: projectId });

    return { project };
  },
});
