





























import { defineComponent, PropType } from "@vue/composition-api";

import { User } from "@/shared/types/schema.types";

export default defineComponent({
  name: "UserListItem",
  props: {
    labelText: {
      type: String,
      default: null,
    },
    user: {
      type: Object as PropType<User>,
      default: null,
    },
  },
});
