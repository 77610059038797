import { ref } from "@vue/composition-api";

import { useRoute, useRouter } from "@/shared/hooks";
import { useProject } from "@/shared/hooks/fetchers";
import { useNotification } from "@/shared/hooks/notification";
import {
  archiveProject,
  removeProject,
  unzipProject,
  updateProjectStatus,
} from "@/shared/requests";
import { Project, ProjectStatusCode } from "@/shared/types/schema.types";

export function useProjectActions() {
  const innerProject = ref<Project>(null as unknown as Project);
  const route = useRoute();
  const projectId = route.params.id;
  const router = useRouter();
  const statusIsUpdating = ref(false);

  const { errNotification, successNotification } = useNotification();
  const {
    data: project,
    loading,
    onResult: onFetchProject,
    refetch,
  } = useProject({ id: projectId });

  onFetchProject(({ data }) => {
    if (!data) return;
    innerProject.value = _.cloneDeep(data.project) as unknown as Project;
  });

  async function editProjectStatus(projectStatusCode: ProjectStatusCode): Promise<void> {
    try {
      statusIsUpdating.value = true;
      const { code, name } = await updateProjectStatus({
        projectStatusCode,
        id: projectId,
      });
      innerProject.value.status.code = code;

      successNotification(`Статус проекта ${innerProject.value.name} изменен на «${name}»`);
      if (refetch) {
        await refetch({ id: projectId });
      }
    } catch (err) {
      innerProject.value = _.cloneDeep(project.value) as unknown as Project;
      errNotification(err);
    } finally {
      statusIsUpdating.value = false;
    }
  }

  async function handlerArchive() {
    try {
      await archiveProject({ id: projectId });
      successNotification(`Проект ${innerProject.value.name} перемещен в архив`);
      if (refetch) {
        await refetch({ id: projectId });
      }
    } catch (err) {
      console.error(err);
      errNotification(err);
    }
  }

  // Удалить проект
  async function handlerDelete() {
    try {
      await removeProject({ id: projectId });
      successNotification(`Проект ${innerProject.value.name} удален`);
      await router.push("/projects");
    } catch (err) {
      console.error(err);
      errNotification(err);
    }
  }

  //Убрать из архива
  async function handleUnzip() {
    try {
      await unzipProject({ id: projectId });
      successNotification(`Проект ${innerProject.value.name} восстановлен из архива`);
      if (refetch) {
        await refetch({ id: projectId });
      }
    } catch (err) {
      console.error(err);
      errNotification(err);
    }
  }

  // Отредактировать проект
  function handleEdit() {
    return router.push(`/projects/${projectId}/edit`);
  }

  return {
    project,
    innerProject,
    loading,
    handlerArchive,
    handlerDelete,
    handleUnzip,
    editProjectStatus,
    statusIsUpdating,
    handleEdit,
    projectId,
    refetch,
  };
}
