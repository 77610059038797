


































































































































import { computed, defineComponent, PropType, ref } from "@vue/composition-api";

import Dropdown from "@/app/components/Base/Dropdown.vue";
import Modal from "@/app/components/Base/Modal.vue";
import PanelDropdown from "@/app/components/Base/PanelDropdown.vue";
import { projectActionPanelEnum } from "@/shared/enums/components/Project/ProjectDescription/projectActionPanel.enum";
import { useProjectStatuses } from "@/shared/hooks/fetchers";
import { Project } from "@/shared/types/schema.types";

export default defineComponent({
  name: "ProjectActionsPanel",
  components: {
    Modal,
    PanelDropdown,
    Dropdown,
  },
  props: {
    isProjectStatusUpdating: {
      type: Boolean,
      default: () => false,
    },
    mode: {
      type: String as PropType<projectActionPanelEnum>,
      default: projectActionPanelEnum.DESCRIPTION,
    },
    project: {
      type: Object as PropType<Project>,
      default: null,
    },
  },
  setup(props, { emit }) {
    const archiveModalIsShown = ref(false);
    const deleteModalIsShown = ref(false);

    const { data: projectStatuses } = useProjectStatuses();

    const isArchived = computed(() => props.mode === projectActionPanelEnum.DESCRIPTION && props.project?.archived);

    const taskStatusIsDisabled = computed(() => props.isProjectStatusUpdating || isArchived.value);

    const editIsShown = computed(() => props.project.availableActions.edit && !isArchived.value && props.mode === projectActionPanelEnum.DESCRIPTION);
    const editResultIsShown = computed(() => props.mode === projectActionPanelEnum.RESULT);
    const deleteIsShown = computed(() => props.project.availableActions.remove && isArchived.value && props.mode === projectActionPanelEnum.DESCRIPTION);
    const additionalActionsIsShown = computed(() => props.mode === projectActionPanelEnum.DESCRIPTION && !isArchived.value);

    function onArchive() {
      archiveModalIsShown.value = false;
      emit('archive');
    }

    function onDelete() {
      deleteModalIsShown.value = false;
      emit('delete');
    }

    return {
      onArchive,
      isArchived,
      taskStatusIsDisabled,
      onDelete,
      archiveModalIsShown,
      deleteModalIsShown,
      projectStatuses,
      projectActionPanelEnum,
      editIsShown,
      editResultIsShown,
      deleteIsShown,
      additionalActionsIsShown,
    };
  },
});
