





















import { computed, defineComponent } from "@vue/composition-api";

import BaseNavigation, { BaseNavigationItem } from "@/app/components/Base/BaseNavigation.vue";
import { PROJECT_ROUTES } from "@/shared/constants";
import { useRoute } from "@/shared/hooks";

interface ProjectNavigationItem extends BaseNavigationItem {
  count?: number;
}

export default defineComponent({
  name: "ProjectNavigation",
  components: { BaseNavigation },
  props: {
    newCommentCount: {
      type: Number,
      default: 0,
    },
    taskCount: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const route = useRoute();
    const menuItems = computed<ProjectNavigationItem[]>(() => {
      const getProjectRouter = PROJECT_ROUTES(route.params.id);
      return [
        {
          title: "Задачи",
          to: getProjectRouter.BASE,
          count: props.taskCount,
        },
        {
          title: "Результат работы",
          to: getProjectRouter.RESULT,
        },
        {
          title: "Описание и команда",
          to: getProjectRouter.TEAM,
        },
        {
          title: "Комментарии",
          to: getProjectRouter.COMMENTS,
          count: props.newCommentCount,
        },
      ];
    });

    function formatCounter(counter: number) {
      if (!counter) return '';
      if (counter > 99) return '99+';
      return counter;
    }

    return {
      menuItems,
      formatCounter, 
    };
  },
});
